import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import styled from 'styled-components';

import { Anchor } from "@webdex/UI/Button";
import { ResponsiveParagraph } from "@webdex/UI/Paragraph";

import StyledImprintText from "./views/ImprintText";
import FooterText from "./views/FooterText";
import NMLogoLink from "./views/NMLogoLink";

const StyledImprintParagraph = styled(ResponsiveParagraph)`
  border-left: 5px dotted yellow;

  padding-left: 0.5em;
`

const serializers = {
  types: {
    block: ({ children }) => (
      <StyledImprintParagraph lg="large" sm="medium" xs="small" withMeasure>
        { children }
      </StyledImprintParagraph>
    )
  },
  marks: {
    link: ({ children, mark }) => (
      mark.newTab ? (
        <Anchor
          href={mark.href}
          target="_blank"
          rel="noopener noreferrer"
          forceUnderline
        >
          { children }
        </Anchor>
      ) : (
        <Anchor href={mark.href} forceUnderline>
          { children }
        </Anchor>
      )
    )
  }
};

const ImprintText = props => {
  const { imprintPage } = props;

  return (
    <StyledImprintText>
      <div>
      <BlockContent
        blocks={imprintPage._rawText}
        serializers={serializers}
      />
      <FooterText>
        <ResponsiveParagraph lg="medium" sm="small" xs="small" withMeasure>
          Sponsored in part by{" "}
          <Anchor
            href="https://www.kw-berlin.de/en/"
            target="_blank"
            rel="noopener noreferrer"
            forceUnderline
          >
            KW Institute for Contemporary Art
          </Anchor>.
        </ResponsiveParagraph>
        <ResponsiveParagraph lg="medium" sm="small" xs="small" withMeasure>
          <NMLogoLink/>
        </ResponsiveParagraph>
      </FooterText>
      </div>
      
    </StyledImprintText>
  );
}

export default ImprintText;
