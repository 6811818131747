import classNames from "classnames";
import styled from "styled-components";

const NMLogoLink = styled.a.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "imprint-page__nm-logo-link"
    ),
    title: "New Models"
  };
})`
  ${({ theme: { space } }) => `
    background-image: url("/images/NM_initials.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    height: ${ space.xLarge };
    width: ${ space.xLarge };
  `}
`;

NMLogoLink.defaultProps = {
  href: "https://newmodels.io",
  target: "_blank",
  rel: "noopener noreferrer"
};

export default NMLogoLink;
