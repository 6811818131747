import classNames from "classnames";
import styled from "styled-components";

const FooterText = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "imprint-page__footer-text"
    )
  };
})`
  ${({ theme: { space } }) => `
    margin-top: ${ space.xLarge };
  `}
`;

FooterText.defaultProps = {};

export default FooterText;
