import classNames from "classnames";
import styled from "styled-components";

const ImprintText = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "imprint-page__imprint-text"
    )
  };
})`
  ${({ theme: { space } }) => `
    padding: ${ space.medium } 0 ${ space.xLarge };
    display: flex;
    justify-content: center;
  `}
`;

ImprintText.defaultProps = {};

export default ImprintText;
