import React from "react";
import { graphql } from "gatsby";

import ImprintPageComponent from "@webdex/Pages/Imprint";

const Imprint = props => {
  const { data } = props;

  return (
    <ImprintPageComponent data={data}/>
  );
};

export const query = graphql`
  query ImprintPage {
    imprintPage: sanityImprintPage {
      ...fieldsOnImprintPage
    }
  }
`;

export default Imprint;
