import React from "react";

import { Metadata } from "@webdex/Core";
import { Col, Row } from "@webdex/UI/Grid";

import ImprintText from "./components/ImprintText";
import StyledImprint from "./views/Imprint";

import Header from "./components/Header";

const Imprint = (props) => {
  const {
    data: { imprintPage },
  } = props;

  return (
    <React.Fragment>
      <Metadata metadata={imprintPage.metadata} />
      <StyledImprint>
        <Row>
          <Col col="8">
            <Header />
            <ImprintText imprintPage={imprintPage} />
          </Col>
        </Row>
      </StyledImprint>
    </React.Fragment>
  );
};

export default Imprint;
